import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Prices from "../containers/prices";

const PricesPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Prices />
  </Layout>
);

export default PricesPage;
