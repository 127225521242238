import * as React from "react";
const Prices = () => (
  <div className="flex flex-wrap overflow-hidden">
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-1">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654842952/training%20center/prices/training-prices_cy1ivd.webp"
        alt="hua hin muay thai team"
      />
    </div>

    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-2 ">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654842952/training%20center/prices/elite-training-pricing_l6o2qe.webp"
        alt="hua hin muay thai team"
      />
    </div>
  </div>
);

export default Prices;
